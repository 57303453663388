import React from 'react'
import ReactPlayer from "react-player";
function Reviews() {
    return (
        <div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                    <div className="col-md-4 mt-3">
                            <div className=" mb-4">
                                <ReactPlayer className="mb-2" width="100%" height="100%" url="https://www.youtube.com/watch?v=rm--VE1xH54&t=4s"/>
                                <div className="card-body">
                                    <div className='coloradd'>
                                    <h5 className="card-text mt-2 mb-2"> <b>PAUL'S HOTEL</b></h5>
                                    </div>
                                    <p style={{color:"grey"}} className="card-text">Hon'ble Sir Samir Paul 🙏 gave the best review to Tanmay Sarkar (MR. INDIAN CODER)  |   Website : <a href='https://www.paulshotel.com/' target='_blank'  rel="noopener noreferrer">https://www.paulshotel.com/</a></p>


                                    <div className="d-flex justify-content-between align-items-center">



                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className=" mb-4">
                                <ReactPlayer className="mb-2" width="100%" height="100%" url="https://www.youtube.com/watch?v=wTeKfooBC7M"/>
                                <div className="card-body">
                                    <div className='coloradd'>
                                    <h5 className="card-text mt-2 mb-2"> <b>M.B. DHAR AND SON JEWELLERS</b></h5>
                                    </div>
                                    <p style={{color:"grey"}} className="card-text">Honorable Sir Rajib Dhar Babu 🙏❤️( owner of M. B. Dhar & Son, Jewellers ) gave a very good review to  Tanmay Sarkar  ( MR. INDIAN CODER )  |   Website : <a href='https://www.mbdharandsonjewellers.com/' target='_blank'  rel="noopener noreferrer">https://www.mbdharandsonjewellers.com/</a></p>


                                    <div className="d-flex justify-content-between align-items-center">



                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className=" mb-4">
                                <ReactPlayer className="mb-2" width="100%" height="100%" url="https://www.youtube.com/watch?v=2INN8eQNLpM"/>
                                <div className="card-body">
                                    <div className='coloradd'>
                                    <h5 className="card-text mt-2 mb-2"> <b>RUPA ECO BRICK</b></h5>
                                    </div>
                                    <p style={{color:"grey"}} className="card-text">Honorable Sir Debapriya Gope Babu 🙏❤️( owner of RUPA ECO BRICK ) gave a very good review to  Tanmay Sarkar  ( MR. INDIAN CODER )  |   Website : <a href='https://www.rupaecobrick.com/' target='_blank'  rel="noopener noreferrer">https://www.rupaecobrick.com/</a></p>
                                    

                                    <div className="d-flex justify-content-between align-items-center">



                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className=" mb-4">
                                <ReactPlayer className="mb-2 nio" width="100%" height="100%" url="https://www.youtube.com/watch?v=xK8PcAqfyeI" />
                                <div className="card-body">
                                    <div className='coloradd'>
                                    <h5 className="card-text mt-2 mb-2"> <b>HOTEL SANGAM</b></h5>
                                    </div>
                                    <p style={{color:"grey"}} className="card-text">Honorable Mam Smt Sipra Guha Mam 🙏❤️( owner of Hotel Sangam ) gave a very good review to  Tanmay Sarkar  ( MR. INDIAN CODER )  |   Website : <a href='https://www.hotelsangams.com/' target='_blank'  rel="noopener noreferrer">https://www.hotelsangams.com/</a></p>
                                    
                                    <div className="d-flex justify-content-between align-items-center">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Reviews