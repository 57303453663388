import React from "react";
import Navbar from "../inc/Navbar";
import TanmayDirector from "../images/mrindiancoder.jpg"
import ScrollToTop from "react-scroll-to-top";

import { Link } from "react-router-dom";


import "./About.css";

function About(props) {


  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <div className='websitedevelopkrishnagar'>
        <div className="album py-2">

          <div className="container mt-4">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">About Us</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <ScrollToTop smooth top="100" color="Gray" />
      <div className="album py-5">

        <div className="container">

          <div className="row">

            <div className="col-md-6 mt-6 me-0" >

              <div className="radiustanmaycoder">
                <img src={TanmayDirector} className="card-img-top" alt="TanmayDirector" />
              </div>

              <div className="d-flex justify-content-between align-items-center">


              </div>

            </div>
            <div className="col-md-6 mt-6" >
              <h1 style={{ color: "Gray" }} className="card-text">Why am I the best?</h1>

              <p className="card-text texts">Our Company mrindiancoder  is a fast growing website development company,     I'm Mr. Indian Coder, a passionate React JS Web Developer from the vibrant land of India. I bring a unique blend of technical expertise and cultural richness to every project I undertake.          </p>
              <h5>Here's what sets me apart:</h5>
              <p className="card-text texts"><b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
              </svg> Deep expertise in React JS: </b>I'm proficient in building modern, interactive, and high-performance websites using React and its ecosystem. From state management libraries like Redux to routing solutions like React Router, I'm equipped to handle projects of all sizes and complexities.</p>
              <p className="card-text texts"> <b> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
              </svg> Unwavering focus on user experience:</b> I believe that websites should be not only visually appealing but also intuitive and user-friendly. I strive to understand your target audience and design websites that cater to their specific needs and preferences. </p>
              <p className="card-text texts"> <b>  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
              </svg> A touch of Indian creativity:</b> I integrate my Indian cultural background into my work, adding a touch of unique flair and visual storytelling to your website. This can be through subtle design elements, animations, or even incorporating traditional Indian motifs.</p>
              <p className="card-text texts"> <b> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
              </svg> Collaborative and communicative:</b> I believe in open communication and collaborating closely with my clients throughout the development process. I ensure that you are involved in every step of the way and are satisfied with the final product.</p>
              <p className="card-text texts"><b> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
              </svg> Commitment to quality:</b> I take pride in delivering high-quality websites that are not only functional but also visually stunning and user-friendly. I believe in exceeding expectations and delivering projects that meet your business goals.</p>
              <br />
              <h5>Here are some specific services I offer:</h5>
              <p className="card-text texts"> <b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
              </svg> React JS Website Development:</b> From static websites to complex Single-Page Applications (SPAs), I can build it all.</p>
              <p className="card-text texts"> <b> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
              </svg> Front-End Development:</b> I can work with various front-end technologies like HTML, CSS, JavaScript, and React to create beautiful and user-friendly interfaces.</p>
              <p className="card-text texts"><b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
              </svg> API Integration:</b> I can integrate your website with various APIs to fetch data, login users, and perform other essential tasks.</p>
              <p className="card-text texts"><b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
              </svg> Responsive Design:</b> I ensure that your website looks and functions seamlessly across all devices, from desktops to smartphones.</p>
              <p> <b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
              </svg> SEO Optimization:</b> I can optimize your website for search engines to improve its visibility and organic traffic.</p>
              <p className="card-text texts"> <b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
              </svg> Maintenance and Support:</b> I offer ongoing maintenance and support services to ensure that your website is always running smoothly and up-to-date.</p>

              <div className="d-flex justify-content-between align-items-center">

              </div>

            </div>
          </div>
        </div>
      </div>



      <hr />

      <div style={{
        backgroundImage: "url(" + " https://i.postimg.cc/zBLy9jrB/abstract-simple-colors-8k-ipad-air.jpg" + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}>
        <div className="album py-5">

          <div className="container">

            <div className="row">

              <div className="col-md-6 mt-6 me-5" >

                <h1 style={{ fontSize: "2rem", color: "white" }} className="card-text"> India's Famous Website Developer - MR. INDIAN CODER</h1>


                <div className="d-flex justify-content-between align-items-center">


                </div>

              </div>
              <div className="col-md-5 mt-6 " >

                <h5 style={{ color: "Gray" }} className="card-text"> <b>Life Time Service (India's Trusted Name Mr. Indian Coder)</b></h5>
                <p style={{ color: "white" }} >*T&C Apply</p>
                <p style={{ color: "white" }} className="card-text texts">We talk honestly with our clients, we share with clients how to make a website, what is domain, what is hosting, what is ssl secure. We develop websites in the world's top languages. The website we created opens pages within 1 second, does not reload. Our mission is to help all businessmen grow their business through website development.

                </p>



                <div className="d-flex justify-content-between align-items-center">

                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="album py-4">

          <div className="container">

            <div className="row">

              <div className="col-md-4 mt-8" >
                <div className="card mb-4 border-White box shadow">
                  <h2 style={{ textAlign: "center", color: "purple", padding: "10px" }} className="card-text"><b>Mission</b></h2>
                  <div className="card-body">

                    <h6 style={{ textAlign: "center" }} className="card-text"> <b>Our company is built to help your business with technology</b></h6>
                    <p className="card-text texts">Our mission is to help all types of businessmen to digitize their business through technology.</p>

                    <br />
                    <div className="d-flex justify-content-between align-items-center">


                    </div>
                  </div>
                </div>

              </div>
              <div className="col-md-4 mt-6" >
                <div className="card mb-4 border-white box shadow">
                  <h2 style={{ textAlign: "center", color: "purple", padding: "10px" }} className="card-text"><b>Vission</b></h2>
                  <div className="card-body">
                    <h6 style={{ textAlign: "center" }} className="card-text"><b>Our vision is to grow your business by creating high quality websites for you.</b></h6>
                    <p className="card-text texts">  The most important technology to move the country progress.
                      I will help all the small and medium business in India with technology so that they can do international business.</p>

                    <div className="d-flex justify-content-between align-items-center">


                    </div>
                  </div>
                </div>

              </div>
              {/* style={{textAlign: "center"}} */}
              <div className="col-md-4" >
                <div className="card mb-4 border-white box shadow">
                  <h2 style={{ textAlign: "center", color: "purple", padding: "10px" }} className="card-text"><b>Expansion</b></h2>
                  <div className="card-body">
                    <h6 style={{ textAlign: "center" }} className="card-text"><b> Across the globe</b></h6>
                    <p className="card-text texts"> We are in our expansion mode, ie to be present in all cities of India and all countries of the world.
                      Currently doing website development in 10+ cities in India and two countries apart from India.</p>

                    <div className="d-flex justify-content-between align-items-center">




                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="album py-3">

        <div className="container">
          <div className="card mb-4 box-shadow">
            <div className="row">

              <div className="col-md-6 ">

                <div className="card-body">

                  <h1 className="mb-3">MR. INDIAN CODER</h1>

                  <h3>India's Famous Website Developer</h3>
                  <div className="d-flex justify-content-between align-items-center">
                  </div>

                </div>
              </div>


              <div className="col-md-5">

                <div className="card-body">
                 
                    <h2 style={{ color: "Gray" }} className="card-text">Global Service </h2>
                
               
                    <h5 style={{ color: "Darkblue" }} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                    </svg> International Website Development Company</h5> 
                
                    <h5 style={{ color: "Darkblue" }} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                    </svg> <small> Life Time Service</small></h5>
                  {/* <marquee width="110%" direction="up" height="100px"> */}
                    <h5 style={{ color: "Darkblue" }} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                    </svg>  The website we created opens pages within 1 second, does not reload.</h5>
                    {/* </marquee> */}
                

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


      <hr />


    </>
  );
}

export default About;